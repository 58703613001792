<template>
	<div class="mb-8">
		<span class="custom-label" v-if="value < 20">
			Data used for current month: {{ quota.quotaused }} / {{ quota.quota }} MB
		</span>
		<b-progress height="2rem" class="progress-container">
			<b-progress-bar variant="info" :value="value" class="striped">
				<span class="custom-label" v-if="value >= 20">
					Total Data used for current month: {{ quota.quotaused }} / {{ quota.quota }} MB
				</span>
			</b-progress-bar>
		</b-progress>
		<!-- Quota breakdown by device || BEGIN -->
		<div v-for="(item) in this.quota_details" v-bind:key="item.deviceInfo.DeviceId" class="mt-3">
			<span class="custom-label" v-if="(item.data_used/quota.quota*100) < 20">
			{{ item.data_used }} / {{ quota.quota }} MB ( {{ item.deviceInfo.Name }} )
			</span>
			<b-progress height="2rem" class="progress-container">
				<b-progress-bar variant="info" :value="(item.data_used/quota.quota*100)" class="striped">
					<span class="custom-label" v-if="(item.data_used/quota.quota*100) >= 20">
						{{ item.data_used }} / {{ quota.quota }} MB ( {{ item.deviceInfo.Name }} )
					</span>
				</b-progress-bar>
			</b-progress>
		</div>
		<!-- Quota breakdown by device || END -->

	</div>
</template>

<script>
export default {
	name: 'SiteQuota',
	props: {
		quota: {
			type: Object,
			required: true,
		},
		quota_details: {
			type: Object,
			required: true,
		}
	},
	computed: {
		value() {
			return (this.quota.quotaused / this.quota.quota) * 100;
		},
	},
};
</script>

<style scoped>
.progress-container {
	background: rgb(199, 199, 199);
}
.striped {
	background-image: linear-gradient(
		45deg,
		hsla(0, 0%, 100%, 0.05) 25%,
		transparent 0,
		transparent 50%,
		hsla(0, 0%, 100%, 0.05) 0,
		hsla(0, 0%, 100%, 0.05) 75%,
		transparent 0,
		transparent
	);
	background-size: 2rem 2rem;
	background-repeat: repeat;
}
.custom-label {
	font-size: 1.3em;
	font-weight: 500;
}
</style>
